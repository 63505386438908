#instructions {
  #instructions-container {
    .instruction-step {
      font-size: calc(0.8rem + 1vw);
      display: flex;
      display: flex;
      align-items: center;
      margin: 10px 0;
      text-transform: uppercase;

      div {
        margin-right: 0.5em;
        width: 1.5em;
        height: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
