#quiz {
  .choice-item {
    aspect-ratio: 1 / 1;
    background: none;
    border: 1px solid black;
    border-radius: 50%;
    margin: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &:active,
    &:focus {
      background-color: $Red;
      color: white !important;
    }

    // filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.4));

    p {
      margin: 0;
    }
  }

  .progress-bar {
    background-color: $Red;
  }
}
