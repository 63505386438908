@font-face {
  font-family: "Uniqlo";
  src: url("../fonts/UniqloProRegular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Uniqlo";
  src: url("../fonts/UniqloProBold.otf") format("opentype");
  font-weight: bold;
}

$Red: #ed1d24;
$Black: #1b1b1b;
$Grey: #7d7d7d;

body {
  font-family: "Uniqlo", sans-serif;
  color: $Black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

p {
  font-weight: normal;
}

.tall-margin {
  margin-top: 0;
  margin-bottom: 0;

  &.quiz {
    margin-top: 1rem;
  }

  @media (min-height: 800px) {
    margin-top: 3rem !important;
    margin-bottom: 3rem;
  }
}

.link-red {
  color: $Red !important;
  text-decoration: underline !important;
  z-index: 999;
}

.bg-red {
  background-color: $Red !important;
}

.text-red {
  color: $Red !important;
}

.text-black {
  color: $Black !important;
}

.red-highlight {
  background-color: $Red;
  color: white;
  padding: 8px;

  &.button {
    transition: all 0.15s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.red-circle {
  background-color: $Red;
  color: white;
  border-radius: 50%;
  display: inline;
}

.link {
  color: $Red;
  text-decoration: underline;
}

.header-box {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hover-scale {
  transition: all 0.15s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}
