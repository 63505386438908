#reward {
  #reward-img {
    margin-bottom: 5em;
    width: 500px;
  }
  #reward-text {
    padding: 10em;
    h1 {
      font-size: 5em;
    }

    p {
      font-size: 2.5em;
    }
  }
}
