#idle {
  h1 {
    font-size: 25em;
    font-weight: bold;
    line-height: 0.85em;
  }

  h2 {
    font-size: 8em;
  }

  #scan-prompt {
    border-color: $Black !important;
    margin: 0 6em;

    h3 {
      font-size: 5em;
    }
  }

  #animation-screen {
    padding: 10em;
  }

  footer {
    height: 180px !important;
  }

  form {
    position: absolute;
    right: 110%;
    bottom: 110%;
  }
}

.small {
  font-size: 0.7em;
}
